<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="defautlPhoto()"></v-img>
        </v-avatar>

        <!-- upload photo -->
        <!-- <div> -->
        <v-col cols="10">
          <div style="padding-top: 25px">
            <v-alert type="error">
              Pastikan anda memasukkan produk barang dengan benar di toko <strong> {{ showtokos.nama_toko }}</strong
              >.
            </v-alert>
          </div>
        </v-col>
      </v-card-text>

      <v-card-text>
        <!-- <v-form class="multi-col-validation mt-6"> -->
        <form ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col md="11" cols="12">
              <v-select
                v-model="jadwals.hari"
                :items="items"
                item-text="text"
                item-value="value"
                label="Hari"
                persistent-hint
                solo
              ></v-select>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Jam Buka &nbsp;</v-header>
              <vue-timepicker v-model="jadwals.jam_buka"></vue-timepicker>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Jam Tutup &nbsp;</v-header>
              <vue-timepicker v-model="jadwals.jam_tutup"></vue-timepicker>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Tutup Sementara</v-header>
              <v-radio-group v-model="jadwals.tutup_sementara" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Tutup Total</v-header>
              <v-radio-group v-model="jadwals.tutup_total" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'tokos-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
        <!-- </v-form> -->
      </v-card-text> </v-card
    ><br />
  </div>
</template>

<script>
// Manually point to the `/src` folder
import VueTimepicker from 'vue2-timepicker/src'
// Or, to the specific file name
// import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

import {
  mdiCheckBold,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
  mdiStoreEdit,
} from '@mdi/js'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
    VueTimepicker,
  },
  data: function () {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }
    return {
      resolveStatisticsIconVariation,

      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Toko',
          disabled: false,
          href: '/admin/tokos',
        },
        {
          text: 'Jadwal',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiCheckBold,
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
        mdiStoreEdit,
      },

      items: [
        { value: 'senin', text: 'Senin' },
        { value: 'selasa', text: 'Selasa' },
        { value: 'rabu', text: 'Rabu' },
        { value: 'kamis', text: 'Kamis' },
        { value: 'jumat', text: 'Jumat' },
        { value: 'sabtu', text: 'Sabtu' },
        { value: 'minggu', text: 'Minggu' },
      ],

      // hari: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'],

      jadwals: [],
      showtokos: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageProduks: 1,
      perPageProduks: 10,
      pagesProduks: [],
      searchProduks: '',
      searchResultProduks: [],
    }
  },

  mounted() {
    this.GetJadwal()

    this.$isLoading(true)
    this.showGambarProduk()
    // this.getAllDataProduk()
    this.GetToko()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    showGambarProduk() {
      const imageProduks = `${process.env.VUE_APP_ROOT_API}`
      return imageProduks
    },

    // getAllDataProduk() {
    //   //state token
    //   localStorage.getItem('token')

    //   let url = process.env.VUE_APP_ROOT_API + `/api/admin/produks/${this.$route.params.id_toko}`
    //   return this.axios.get(url).then(response => {
    //     this.dataproduks = response.data.dataproduk
    //     this.gambarproduks = response.data.gambarproduk
    //     // console.log('NAMA ROLE: ' + response.data.users.role_rel.nama_role)
    //   })
    // },

    CreateData(e) {
      // console.log('PHOTO: ' + this.produk.id_kategoriproduk)
      if (this.$data.jadwals.hari.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Hari required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.jadwals.jam_buka.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Jam Buka required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.jadwals.jam_tutup.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Jam Tutup required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        const uri = `${process.env.VUE_APP_ROOT_API}/api/admin/jadwals/${this.$route.params.id_jadwal}`

        this.axios
          .post(uri, {
            id_toko: this.$route.params.id_toko,
            hari: this.jadwals.hari,
            jam_buka: this.jadwals.jam_buka,
            jam_tutup: this.jadwals.jam_tutup,
            tutup_sementara: this.jadwals.tutup_sementara,
            tutup_total: this.jadwals.tutup_total,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Jadwal created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            setTimeout(() => {
              // this.$router.push({ name: 'produk-index' })
              window.location.reload()
            }, 3000)
          })

        e.preventDefault()
      }
    },

    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/icon/tokobarang.png`
      return imageDefault
    },

    GetJadwal() {
      let uriJadwal =
        process.env.VUE_APP_ROOT_API +
        `/api/admin/jadwals/${this.$route.params.id_jadwal}/${this.$route.params.id_toko}/show`
      this.axios.get(uriJadwal).then(response => {
        this.jadwals = response.data.jadwals
      })
    },

    GetToko() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri = process.env.VUE_APP_ROOT_API + `/api/admin/jadwals/${this.$route.params.id_toko}`
      this.axios.get(uri).then(response => {
        this.showtokos = response.data.showtoko
        // this.jadwals = response.data.jadwal
        // console.log(this.role.nama_role)
      })
    },

    // Paging Produk
    doSearchProduks() {
      const go = this.jadwals.filter(item =>
        this.searchProduks
          .toLowerCase()
          .split(' ')
          .every(v => item.hari.toLowerCase().includes(v)),
      )
      this.searchResultProduks = go
      this.pagesProduks = []
      this.setPagesDataProduks()
    },
    displayDataProduks() {
      let paginateProduks

      if (this.searchProduks == '') {
        paginateProduks = this.paginateProduks(this.jadwals)
      } else {
        paginateProduks = this.paginateProduks(this.searchResultProduks)
      }

      return paginateProduks
    },
    setPagesDataProduks() {
      let numberOfPages

      if (this.searchProduks == '') {
        numberOfPages = Math.ceil(this.jadwals.length / this.perPageProduks)
      } else {
        numberOfPages = Math.ceil(this.searchResultProduks.length / this.perPageProduks)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesProduks.push(index)
      }
    },
    paginateProduks(jadwals) {
      const { pageProduks } = this
      const { perPageProduks } = this
      const from = pageProduks * perPageProduks - perPageProduks
      const to = pageProduks * perPageProduks

      return jadwals.slice(from, to)
    },
    // End Paging Produk

    doSearch() {
      const go = this.kategoriproduk.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kategoriproduk.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.kategoriproduk)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.kategoriproduk.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(kategoriproduk) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return kategoriproduk.slice(from, to)
    },
  },
  watch: {
    kategoriproduk() {
      this.pages = []
      this.setPages()
    },

    jadwals() {
      this.pagesProduks = []
      this.setPagesDataProduks()
    },
  },
}
</script>
